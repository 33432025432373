import * as React from "react";
import {Link }from "react-router-dom";


function Footer(){
    return (
        <footer className="footer-container d-flex justify-content-between flex-wrap">
            <span>&copy; 2016 - { new Date().getUTCFullYear() } Clever Compliance AB</span>
            <span>
            
                <Link to={"//support.ce-check.eu"} target="_blank"> Knowledgebase</Link>
                {/* <a href="https://support.ce-check.eu" target="_blank">Knowledgebase</a> */}
            </span>
            <span>
                <a href="mailto:support@clevercompliance.i" >Support</a>
            </span>
            <span>
                <Link to="//clevercompliance.io/en/terms-of-service" target="_blank"> Terms and conditions</Link>
                     {/* <a href="https://clevercompliance.io/en/terms-of-service" target="_blank">Terms and conditions</a> */}
            </span>
            <span>
                <Link to="//clevercompliance.io/en/privacy-policy" target="_blank">Privacy policy</Link>
                     {/* <a href="https://clevercompliance.io/en/privacy-policy" target="_blank">Privacy policy</a> */}
            </span>
        </footer>
    
    );

}

export default Footer