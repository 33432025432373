import { makeAutoObservable} from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";
import {CustomForm} from "../models/CustomForms";

export default class CustomFormStore {
    rootStore : RootStore;
    _questions:CustomForm[] = [];
    private _blockHelper = new BlockHelper();

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false} as any);
        this.rootStore = rootStore;
    }

    async defaultQuestion(){
        return {
            fieldName:"",
            fieldType:"text",
            values:[]
        }
    }

    async addCustomForms(customForm:CustomForm){
        let res = await this.rootStore.apiClient.post("CustomForms",customForm);
        let customFormResponse = Unwrap.result<CustomForm>(res)!;
        if(customFormResponse.id){
            return customFormResponse;
        }
        return false;
    }
    async updateCustomForms(customForm:CustomForm){
        let res = await this.rootStore.apiClient.put("CustomForms",customForm);
        let customFormResponse = Unwrap.result<CustomForm>(res)!;
        if(customFormResponse.id){
            return customFormResponse;
        }
        return false;
    }

    async deleteCustomForms(forms:any){
        await this.rootStore.apiClient.delete(`CustomForms/${forms.id}`);
    }
    async getCustomForms(id:any){
        let res = await this.rootStore.apiClient.get(`CustomForms/${id}`);
        if(res){return res ;}
        return false;
    }
}