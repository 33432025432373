import React, { lazy,Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "animate.css/animate.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/app.scss";

import { reaction } from "mobx";
import Header from "./components/Layout/Header";
import Sidebar from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";
import { SignInCallback, SignOutCallback, SignInSilentCallback } from "./components/Auth/Callback";
import AuthenticatedRoute from "./components/Auth/AuthenticatedRoute";
import { LoadingWrapper } from "./components/LoadingWrapper";
import RootStore from "./store/RootStoreContext";
import RootStoreInstance from "./store/RootStore"

const Suppliers = lazy(() => import( "./components/Suppliers/Suppliers"));
const Articles = lazy(() => import( "./components/Articles/Articles"));
const CustomForms = lazy(() => import( "./components/CustomForms/CustomForms"));
const AddFormsData = lazy(() => import( "./components/CustomForms/AddFormsData"));
const EditFormsData = lazy(() => import( "./components/CustomForms/AddFormsData"));
const AnswerPage = lazy(() => import( "./components/AnswerPage/AnswerPage"));
const Requests = lazy(() => import( "./components/Requests/Requests"));
const ShowAnswer = lazy(() => import( "./components/Requests/ShowAnswer"));
const Reports = lazy(() => import( "./components/Reports/Reports"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));




reaction(() => {
  return RootStoreInstance.sessionStore.authenticated
}, (auth) => {
  if (auth) RootStoreInstance.sessionStore.loadCurrentLicense(false);
});

const App = function () {
  return <BrowserRouter>
    <RootStore>
      <div className="layout-fixed">
        <div className="wrapper">
          <Header />
          <Sidebar />
          <Suspense fallback={<LoadingWrapper loaders={[{ isLoading: true, text: "Loading page..." }]}>Loading...</LoadingWrapper>}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/account/signin-oidc" component={SignInCallback} />
              <Route exact path="/account/signout-oidc" component={SignOutCallback} />
              <Route exact path="/account/silent-renew" component={SignInSilentCallback} />
              <AuthenticatedRoute exact path="/suppliers" component={Suppliers}/>
              <AuthenticatedRoute exact path="/articles" component={Articles}/>
              <AuthenticatedRoute exact path="/forms" component={CustomForms}/>
              <AuthenticatedRoute exact path="/forms/AddFormsData" component={AddFormsData }/>
              <AuthenticatedRoute exact path="/forms/EditFormsData/:id" component={EditFormsData}/>
              <AuthenticatedRoute exact path="/requests" component={Requests}/>
              <AuthenticatedRoute exact path="/show_answer/:batchid" component={ShowAnswer}/>
              <AuthenticatedRoute exact path="/reports" component={Reports}/>
              <Route exact path="/rfi/:spaceId/:rfiHash/:contactHash" component={AnswerPage} />
            </Switch>
          </Suspense>
          <Footer />
        </div>
      </div>
      {/* https://supplierportal.dev.clevercompliance.io/rfi/9fe88a4d774a2ef3454a76a4e683625fd666e6eb02d15390a27ef5fbaffc4d2c/d55f8072f37bac2bd112775dfa8d0ef3bea51fa5ab608c82cdc3f198b05ebe0c */}
    </RootStore>
  </BrowserRouter>
}

export default App;
