import { makeAutoObservable} from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";
import {Supplier} from "../models/Supplier";


export class NotFoundSupplier {
    id: string;
    constructor(id : string) {
        this.id = id;
    }
}
export default class SupplierStore {
    rootStore : RootStore;
    //private _suppliers:(Supplier | NotFoundSupplier) [] = [];
    private _blockHelper = new BlockHelper();

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false} as any);
        this.rootStore = rootStore;
    }

    async addSupplier(supplier:Supplier){
        let res = await this.rootStore.apiClient.post("Suppliers",supplier);
        let supplierResponse = Unwrap.result<Supplier>(res)!;
        if(supplierResponse.id){
            return supplierResponse;
        }
        return false; // need to set validate meesage coming from apis
    }

    async updateSupplier(supplier:Supplier){
        let res = await this.rootStore.apiClient.put(`Suppliers`,supplier);
        let updatedSupplier =  Unwrap.result<Supplier>(res)!;
        if(updatedSupplier.id){
            return updatedSupplier;
        }
        return false;
    }

    async deleteSupplier(supplier:Supplier){
        await this.rootStore.apiClient.delete(`Suppliers/${supplier.id}`);
    }

    clear() {
        //this._suppliers = [];
        this._blockHelper.clear();
    }
}