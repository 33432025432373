import config from "./config";
import { WebStorageStateStore } from "oidc-client";

export const IDENTITY_CONFIG = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: config.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
    client_id: config.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: config.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
    login: config.REACT_APP_AUTH_URL + "/login",
    automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: config.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: config.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
    // audience: "https://example.com", //is there a way to specific the audience when making the jwt
    response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    // grantType: "password",
    scope: "openid profile SupplierPortal.InternalApi.V1",//(string, default: 'openid'): The scope being requested from the OIDC provider.
    // webAuthResponseType: "id_token token",
    filterProtocolClaims: true,
    popupWindowFeatures: 'location=no,toolbar=no,width=500,height=600,left=100,top=100'
};


// export const METADATA_OIDC = {
//     issuer: "https://identityserver",
//     jwks_uri: config.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
//     authorization_endpoint: config.REACT_APP_AUTH_URL + "/connect/authorize",
//     token_endpoint: config.REACT_APP_AUTH_URL + "/connect/token",
//     userinfo_endpoint: config.REACT_APP_AUTH_URL + "/connect/userinfo",
//     end_session_endpoint: config.REACT_APP_AUTH_URL + "/connect/endsession",
//     check_session_iframe: config.REACT_APP_AUTH_URL + "/connect/checksession",
//     revocation_endpoint: config.REACT_APP_AUTH_URL + "/connect/revocation",
//     introspection_endpoint: config.REACT_APP_AUTH_URL + "/connect/introspect"
// };
